<template>
  <card-component title="Editar Perfil" icon="account-circle" class="profile-card">
    <b-notification :type="mensaje.color" v-if="mensaje.ver">
      {{mensaje.texto}}
    </b-notification>
    <form @submit.prevent="submit">
      <!--<b-field horizontal label="Avatar">
        <file-picker/>
      </b-field>
      <hr>-->
      <b-field horizontal label="Nombre">
        <b-input v-model="usuario.nombre" name="name" required/>
      </b-field>
      <b-field horizontal label="Correo">
        <b-input v-model="usuario.correo" name="email" type="email"/>
      </b-field>
      <hr>
      <b-field horizontal>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{'is-loading':isLoading}">
            Actualizar
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
// import FilePicker from '@/components/template/FilePicker'
import CardComponent from '@/components/template/CardComponent'

export default {
  name: 'ProfileUpdateForm',
  components: {
    CardComponent
    // FilePicker
  },
  data () {
    return {
      isFileUploaded: false,
      isLoading: false,
      form: {
        name: null,
        email: null
      }
    }
  },
  computed: {
    usuario () {
      return this.$store.state.usuario
    },
    mensaje () {
      return this.$store.getters.mensaje
    }
  },
  methods: {
    submit () {
      this.$store.dispatch('actualizarUsuario', this.usuario)
    }
  }
}
</script>
