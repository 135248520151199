<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      Perfil
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <profile-update-form class="tile is-child"/>
        <card-component title="Perfil" icon="account" class="tile is-child profile-card">
          <user-avatar class="has-max-width is-aligned-center"/>
          <hr>
          <b-field label="Nombre de usuario">
            <b-input :value="usuario.nombreUsuario" custom-class="is-static" readonly/>
          </b-field>
          <hr>
          <b-field label="Nombre">
            <b-input :value="usuario.nombre" custom-class="is-static" readonly/>
          </b-field>
          <hr>
          <b-field label="Correo eléctronico">
            <b-input :value="usuario.correo" custom-class="is-static" readonly/>
          </b-field>
        </card-component>
      </tiles>
      <password-update-form/>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/template/CardComponent'
import TitleBar from '@/components/template/TitleBar'
import HeroBar from '@/components/template/HeroBar'
import ProfileUpdateForm from '@/components/template/ProfileUpdateForm'
import PasswordUpdateForm from '@/components/template/PasswordUpdateForm'
import Tiles from '@/components/template/Tiles'
import UserAvatar from '@/components/template/UserAvatar'
export default {
  name: 'Profile',
  components: { UserAvatar, Tiles, PasswordUpdateForm, ProfileUpdateForm, HeroBar, TitleBar, CardComponent },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Perfil'
      ]
    },
    usuario () {
      return this.$store.state.usuario
    }
  },
  created () {
    this.$store.dispatch('obtenerUsuario')
  },
  methods: {
    reload () {
      this.$store.dispatch('obtenerUsuario')
    }
  }
}
</script>
