<template>
  <card-component title="Cambiar clave" icon="lock" class="profile-card">
    <b-notification :type="mensaje.color" v-if="mensaje.ver">
      {{mensaje.texto}}
    </b-notification>
    <form @submit.prevent="submit">
      <input type="text" style="display:none">
      <input type="password" style="display:none">

      <b-field horizontal label="Clave actual">
        <b-input name="password_current" type="password" v-model="form.passwordCurrent" required
          autocomplete="new-password"/>
      </b-field>
      <hr>
        <b-field horizontal label="Nueva clave">
          <b-input name="password" type="password" v-model="form.password" required
            autocomplete="new-password"/>
        </b-field>

        <b-field horizontal label="">
          <b-progress type="is-info" :value="score"></b-progress>
        </b-field>

        <b-field horizontal label="Repetir clave">
          <b-input name="password_confirmation" type="password" v-model="form.passwordConfirmation" required
            autocomplete="new-password"/>
        </b-field>
      <hr>
      <b-field horizontal>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{'is-loading':isLoading}">
            Guardar
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/template/CardComponent'
export default {
  name: 'PasswordUpdateForm',
  components: {
    CardComponent
  },
  data () {
    return {
      isLoading: false,
      form: {
        passwordCurrent: null,
        password: null,
        passwordConfirmation: null
      },
      score: 0
    }
  },
  watch: {
    'form.password': async function (valor) {
      this.score = await this.scorePassword(valor)
    }
  },
  computed: {
    mensaje () {
      return this.$store.getters.mensaje
    },
    usuario () {
      return this.$store.getters.usuario
    }
  },
  methods: {
    async submit () {
      if (await this.scorePassword(this.form.password) < 60) {
        this.$store.dispatch('mostrarMensaje', {
          texto: 'Las nueva clave es demasiado débil',
          color: 'is-danger',
          segundos: 5
        })
      } else {
        if (this.form.password === this.form.passwordConfirmation) {
          const result = await this.$store.dispatch('actualizarClave', {
            nuevaClave: this.form.password,
            clave: this.form.passwordCurrent,
            idUsuario: this.usuario.id
          })
          if (result === 'OK') {
            this.form = {
              passwordCurrent: null,
              password: null,
              passwordConfirmation: null
            }
          }
        } else {
          this.$store.dispatch('mostrarMensaje', {
            texto: 'Las claves no coinciden',
            color: 'is-danger',
            segundos: 5
          })
        }
      }
    },
    scorePassword (pass) {
      return new Promise((resolve) => {
        var score = 0
        if (!pass) {
          resolve(score)
        }

        if (pass.length >= 10) {
          score += 50
        }

        // Bonus points for mixing it up
        var variations = {
          digits: /\d/.test(pass),
          lower: /[a-z]/.test(pass),
          upper: /[A-Z]/.test(pass),
          nonWords: /\W/.test(pass)
        }

        if (!variations.digits) {
          score -= 12
        } else {
          score += 12
        }

        if (!variations.lower) {
          score -= 12
        } else {
          score += 12
        }

        if (!variations.upper) {
          score -= 12
        } else {
          score += 12
        }

        if (!variations.nonWords) {
          score -= 14
        } else {
          score += 14
        }

        resolve(parseInt(score))
      })
    }
  }
}
</script>
